import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'header': () => import('./components/top-navigation'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'short-content-swiper': () => import('./components/short-content-swiper'),
        'image-gallery': () => import('./components/image-gallery'),
        'accordion': () => import('./components/accordion'),
        'top-navigation': () => import('./components/top-navigation'),
        'media-with-video': () => import('./components/media-with-video'),
        'swiper': () => import('../../node_modules/swiper/swiper-bundle.js'),
        'modal': () => import('./components/modal'),
        'map': () => import('./components/map'),
        'slider-section-slider': () => import('./components/slider-section-slider'),
        'employee-branding-slider': () => import('./components/employee-branding-slider'),
        'slider-section-slider--footer': () => import('./components/slider-section-slider--footer'),
        'overview-controller': () => import('./components/overview-controller'),
        'overview-controller-employee': () => import('./components/overview-controller-employee'),
        'dropdown': () => import('./components/dropdown'),
        'switch-universe': () => import('./components/switch-universe'),
        'mobile-navigation': () => import('./components/mobile-navigation'),
        'split-text': () => import('./components/split-text'),
        'mandatory-field': () => import('./components/mandatory-field'),
        /*
        This is an example component that demonstrates how to implement a filterable map. It should not be used in production sites as is but can serve as inspiration.
        'domain-filterable-map': () => import('./components/example-components/example-domain-filterable-map'),
        */
    });
});
